<script lang="ts">
	import { type ProductCardMissingInfo$result, ProductCardMissingInfoStore } from '$houdini';
	import type { RequiredProduct } from '$lib/types';
	import { type MetaField, isNetworkError } from '$lib/utils';
	import { classes } from '@thearc-hq/ui-kit/utils';
	import Image from '../Image/Image.svelte';
	import ProductPriceRange from './ProductPriceRange.svelte';
	import { page } from '$app/stores';
	import { getTranslate, T } from '@tolgee/svelte';
	import SaleChip from '$lib/components/Product/SaleChip.svelte';
	import { resolveRoute } from '$app/paths';
	import { languageStore } from '$lib/store/language.store';
	import ProductCardSelector from '$lib/components/Product/Card/ProductCardSelector.svelte';
	import ButtonPrimary from '$lib/components/Button/ButtonPrimary.svelte';
	import { cart } from '$lib/store/cart.store';
	import GenericDisclosure from '$lib/components/GenericDisclosure.svelte';
	import { createBreakpointStore } from '$lib/store/breakpoint.store';
	import { globalAddedToCartStore } from '$lib/store/globalAddedToCart.store';
	import { recordAddToCart } from '$lib/posthog';
	import { addSDS, trimSDS } from '$lib/utils/sds';
	import { ensureCountry, ensureLocale } from '$lib/utils/i18n';
	import {
		mapToNode,
		parseHighlight,
		percentOf,
		transformProductCollectionsToBranch
	} from '$lib/utils/product';
	import { parseImageUrls } from '$lib/utils/image';
	import { onVisible } from '$lib/utils/intersection';
	import slug from 'slug';
	import { getCollectionPath } from '$lib/utils/collectionTree';

	export let className: string | undefined = '';
	export let imageUrls: any;
	export let title: string;
	export let priceRange: RequiredProduct['priceRange'];
	export let compareAtPriceRange: RequiredProduct['compareAtPriceRange'] | undefined;
	export let handle: string;
	export let variants:
		| NonNullable<ProductCardMissingInfo$result['product']>['variants']
		| undefined;
	export let sku: string | undefined | MetaField;
	export let viewportSize = '(min-width: 50em) 25vw, 50vw';
	export let sourceExtra: string | undefined = undefined;
	export let branch: string | undefined = undefined;
	/**
	 * Additional Props like priority decoding etc.
	 */
	export let imageProps = {};

	let localSku = typeof sku === 'string' ? sku : sku?.value;

	const { t } = getTranslate();

	let hasCompletedSelection = false;
	let mobileClosureOpen = false;
	let isVisible = false;
	const isMobile = createBreakpointStore();
	const extendedData = new ProductCardMissingInfoStore();
	let retries = 0;

	$: if (isVisible && retries < 5) {
		extendedData
			.fetch({
				variables: {
					handle: trimSDS(handle),
					sdsHandle: addSDS(handle),
					language: ensureLocale($languageStore.locale),
					country: ensureCountry($languageStore.country)
				}
			})
			.catch((e) => {
				if (isNetworkError(e)) {
					setTimeout(
						() => {
							retries++;
						},
						2000 * (retries + 1)
					);
				} else {
					throw e;
				}
			});
	}

	$: selectedVariant = (
		$extendedData.data ? mapToNode($extendedData.data?.product?.variants) : mapToNode(variants)
	).find((node) => node.sku?.value === localSku);

	$: sdsVariant = (
		$extendedData.data ? mapToNode($extendedData.data?.sdsProduct?.variants) : mapToNode(variants)
	).find((node) => node.sku?.value === localSku);

	$: parsedImageUrls =
		(selectedVariant
			? parseImageUrls(selectedVariant.imageUrls) ??
				parseImageUrls(imageUrls) ??
				parseImageUrls($extendedData.data?.product?.imageUrls)
			: parseImageUrls(imageUrls) ?? parseImageUrls($extendedData.data?.product?.imageUrls)) || [];
	$: parsedHighlight = parseHighlight($extendedData.data?.product?.highlights);
	$: hasStock =
		hasCompletedSelection && selectedVariant && sdsVariant
			? $cart.getRealQuantity(
					selectedVariant.sku?.value || '',
					Math.max(selectedVariant.quantityAvailable || 0, sdsVariant.quantityAvailable || 0)
				) > 0
			: true;
	$: hasAnyStock = $extendedData.data
		? mapToNode($extendedData.data?.sdsProduct?.variants).some(
				(n) => (n.quantityAvailable ?? 0) > 0
			)
		: true;
	$: highestSale = $extendedData.data?.product
		? Math.max(
				...mapToNode($extendedData.data.product.variants).map(
					(e) => percentOf(e.priceV2, e.compareAtPriceV2) || 0
				)
			)
		: 0;
	$: href = localSku
		? resolveRoute(`/[locale]-[country]/[branch=branch]/[slug]/[handle]?variantSKU=${localSku}`, {
				...$page.params,
				slug: slug(title),
				handle,
				branch
			})
		: resolveRoute('/[locale]-[country]/[branch=branch]/[slug]/[handle]/', {
				...$page.params,
				slug: slug(title),
				handle,
				branch
			});

	$: if ($extendedData.data?.product?.is_single?.value === 'true') {
		localSku = $extendedData.data.product.variants?.edges?.[0]?.node?.sku?.value || '';
	}

	const addToCart = async () => {
		if (!selectedVariant || !sdsVariant) {
			return;
		}
		recordAddToCart({
			sku: selectedVariant.sku?.value || '',
			source: `Product Card${sourceExtra ? ` (${sourceExtra})` : ''}`,
			quantity: 1,
			handle: handle
		});
		const lineId = await cart.addSDSItem({
			sku: selectedVariant.sku?.value || '',
			variantId: selectedVariant.id,
			sdsId: sdsVariant.id,
			sdsThreshold: Number(selectedVariant.sds_qty?.value || '10') || 10,
			quantity: 1,
			handle: handle,
			sdsOnHand: sdsVariant.quantityAvailable || 0,
			avantradoOnHand: selectedVariant.quantityAvailable || 0
		});
		if (lineId) {
			globalAddedToCartStore.addLineIDs(lineId);
			globalAddedToCartStore.setIsExpanded(true);
		}
	};

	let selectedImage = 0;
	let selectedImageBeforeHover = 0;

	$: if (selectedImage > parsedImageUrls.length) {
		selectedImage = 0;
	}

	const onHover = () => {
		if (parsedImageUrls.length > 1 && selectedImage === 0) {
			selectedImageBeforeHover = selectedImage;
			selectedImage = 1;
		}
	};

	const onHoverEnd = () => {
		if (parsedImageUrls.length > 1 && selectedImage === 1 && selectedImageBeforeHover === 0) {
			selectedImage = selectedImageBeforeHover;
		}
	};

	export { className as class };
	$$restProps;
</script>

<div
	class={classes(
		'relative flex flex-col justify-between md:block group hover:shadow-2xl shadow-xl md:shadow-none h-fit',
		className,
		!hasAnyStock && 'order-last'
	)}
	use:onVisible={() => {
		isVisible = true;
	}}
>
	<div
		class="radial-gradient-product-image w-full aspect-square grid place-items-center relative group/image"
		style:background="radial-gradient(#FFFFFF 0%, #F2F2F8 100%)"
		role="button"
		tabindex={0}
		on:mouseenter={() => onHover()}
		on:mouseleave={() => onHoverEnd()}
		on:touchstart={() => onHover()}
		on:touchend={() => onHoverEnd()}
		on:touchcancel={() => onHoverEnd()}
	>
		{#if parsedImageUrls.length > 1}
			<button
				on:click={() => {
					if (selectedImage === 0) {
						selectedImage = parsedImageUrls.length - 1;
					} else {
						selectedImage--;
					}
				}}
				class="absolute bg-opacity-80 left-3 top-1/2 transform -translate-y-1/2 rounded-full bg-gray-200 grid place-items-center w-12 h-12 opacity-0 transition -translate-x-4 duration-500 group-hover/image:(opacity-100 translate-x-0) hover:border border-accent-primary"
			>
				<span class="i-sprenger-chevron-left w-7 h-7 text-gray-600"></span>
			</button>
		{/if}
		<a on:click class="w-full h-full" {href}>
			{#if parsedImageUrls.length > 0}
				<Image
					{...imageProps}
					src={parsedImageUrls[selectedImage]}
					alt=""
					width={768}
					height={768}
					sizes={viewportSize}
					class="w-full h-full object-cover"
					shouldShowLoad
				/>
			{:else}
				<div class="w-full h-full bg-light-secondary grid place-items-center">
					<div class="i-sprenger-image w-8 h-8 bg-gray"></div>
				</div>
			{/if}
		</a>

		{#if parsedImageUrls.length > 1}
			<button
				on:click|stopPropagation={() => {
					if (selectedImage === parsedImageUrls.length - 1) {
						selectedImage = 0;
					} else {
						selectedImage++;
					}
				}}
				class="absolute bg-opacity-80 right-3 top-1/2 transform -translate-y-1/2 rounded-full bg-gray-200 grid place-items-center w-12 h-12 opacity-0 transition translate-x-4 duration-500 group-hover/image:(opacity-100 translate-x-0) hover:border border-accent-primary"
			>
				<span class="i-sprenger-chevron-right w-7 h-7 text-gray-600"></span>
			</button>
		{/if}
	</div>

	<a {href} class="h-full">
		<div class="p-1 h-32 md:h-auto">
			<p
				class="line-clamp-2 !normal-case text-h-16 text-opacity-75 font-extrabold line-clamp-2 hover:text-dark-secondary"
			>
				{title}
			</p>
			{#if parsedHighlight}
				<p class="line-clamp-2">{parsedHighlight}</p>
			{/if}
			<ProductPriceRange
				{priceRange}
				{compareAtPriceRange}
				class="text-accent-primary !items-start"
			/>
		</div>
	</a>
	{#if $extendedData.data?.product}
		<div
			class="relative md:absolute group-hover:visible md:invisible w-full md:p-2 z-20 bg-white left-0 {mobileClosureOpen
				? 'shadow-2xl'
				: ''} md:group-hover:shadow-2xl md:shadow-xl"
		>
			<div class="hidden md:block">
				{#if $extendedData.data.product?.is_single?.value !== 'true'}
					<ProductCardSelector
						on:complete={() => (hasCompletedSelection = true)}
						on:incomplete={() => (hasCompletedSelection = false)}
						data={$extendedData.data}
						bind:sku={localSku}
					/>
				{/if}

				<ButtonPrimary
					on:click={async () => {
						await addToCart();
					}}
					text={!hasStock ? $t('product.outOfStock') : $t('product.intoCart')}
					disabled={!hasStock ||
						($extendedData.data?.product?.is_single?.value !== 'true' && !hasCompletedSelection)}
					class="w-full mt-4"
				/>
			</div>
			<hr class="md:hidden mb-2" />
			<GenericDisclosure bind:open={mobileClosureOpen} class="md:hidden relative w-full pb-1">
				<button
					on:click={async () => {
						if (isMobile && $extendedData.data?.product?.is_single?.value === 'true') {
							await addToCart();
							return;
						}
						mobileClosureOpen = !mobileClosureOpen;
					}}
					disabled={isMobile && $extendedData.data?.product?.is_single?.value === 'true'
						? !hasStock
						: false}
					class="flex w-full justify-between px-2"
				>
					<p
						class="text-h-12 text-opacity-75 font-extrabold line-clamp-2 hover:text-dark-secondary"
					>
						{$t('productcard_mobile_addtocart')}
					</p>

					{#if $extendedData.data.product?.is_single?.value !== 'true'}
						<div
							class="i-sprenger-chevron-down w-5 h-5 transition transform"
							class:rotate-180={mobileClosureOpen}
						></div>
					{/if}
				</button>
				<div
					slot="content-open"
					class="absolute bg-white md:static w-full !min-w-[10.21875rem] px-2 py-2 shadow-xl md:shadow-none"
				>
					{#if $extendedData.data.product?.is_single?.value !== 'true'}
						<ProductCardSelector
							on:complete={() => (hasCompletedSelection = true)}
							on:incomplete={() => (hasCompletedSelection = false)}
							data={$extendedData.data}
							bind:sku={localSku}
						/>
					{/if}

					<ButtonPrimary
						on:click={async () => {
							await addToCart();
						}}
						text={!hasStock ? $t('product.outOfStock') : $t('product.intoCart')}
						disabled={!hasStock ||
							($extendedData.data?.product?.is_single?.value !== 'true' && !hasCompletedSelection)}
						class="w-full mt-4"
					/>
				</div>
			</GenericDisclosure>
		</div>
	{/if}
	<div class="absolute z-10 flex items-center left-2 top-1 gap-2">
		{#if !hasAnyStock}
			<div
				class="bg-gray-200 border rounded text-p-12 text-dark-tertiary border-dark-05 px-2 py-1 h-[1.625rem]"
			>
				<T keyName="outOfStock" />
			</div>
		{/if}
		<SaleChip sale={highestSale} />
	</div>
</div>
